import {Environment, selectDomain} from '../app/utils/select-domain';

export enum env{
  localhost = 'http://127.0.0.1:8000/',
  developer = 'https://storage.dev.3303.ua/',
  demo = 'https://storage-demo.3303.ua/',
  demodev = 'https://storage-demo.dev.3303.ua/',
  production = 'https://storage.3303.ua/',
	delicateline = 'https://storage.delicateline.com/'
}

export const domains: Environment[] = [
  {
    server: env.production,
    domain: 'https://crm.3303.ua'
  },
  {
    server: env.developer,
    domain: 'https://crm.dev.3303.ua'
  },
  {
    server: env.demo,
    domain: 'https://crm-demo.3303.ua'
  },
  {
    server: env.demodev,
    domain: 'https://crm-demo.dev.3303.ua'
  },
  {
    server: env.localhost,
    domain: 'http://localhost:4200'
  },
	{
		server: env.delicateline,
		domain: 'https://crm.delicateline.com'
	}
];

export const environment = {
  production: true,
  url: selectDomain()
};
console.log(`---------URL: ${environment.url}-------`);
console.log(`----------PRODUCTION MODE: ${environment.production}----------------`);
